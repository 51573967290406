import React from 'react';
import { useEnvironmentContext } from '../environment-context/environmentContext';
import { LinkProps } from '../environment-context/types';

/**
 * @returns Link element using useEnvironmentContext hook.
 * This behaves how users expect CMS links to behave - if starts with http(s):// should open in new tab, unless they specify forceOpenSameTab
 */
const CmsLink = ({
  children,
  forceOpenSameTab,
  ...forwardProps
}: LinkProps & { forceOpenSameTab?: boolean }) => {
  const { Link } = useEnvironmentContext();
  return (
    <Link
      target={
        forwardProps.href.startsWith('http') && !forceOpenSameTab
          ? '_blank'
          : '_self'
      }
      {...forwardProps}
    >
      {children}
    </Link>
  );
};

export { CmsLink };

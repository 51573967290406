import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { screenSizes } from '@allergan-data-labs/universal-component-library/src/utils/mediaTemplate';
import {
  AlleElementsComponentThemes,
  AlleElementsProvider,
} from '@allergan-data-labs/alle-elements-core';

import { AccordionTheme } from '@allergan-data-labs/alle-elements-accordion';
import { AvatarTheme } from '@allergan-data-labs/alle-elements-avatar';
import { BadgeTheme } from '@allergan-data-labs/alle-elements-badge';
import { ButtonTheme } from '@allergan-data-labs/alle-elements-button';
import { BreadcrumbTheme } from '@allergan-data-labs/alle-elements-breadcrumb';
import { CardTheme } from '@allergan-data-labs/alle-elements-card';
import { CheckboxTheme } from '@allergan-data-labs/alle-elements-checkbox';
import {
  FormTheme,
  FormLabelTheme,
  FormErrorTheme,
} from '@allergan-data-labs/alle-elements-form-control';
import { InputTheme } from '@allergan-data-labs/alle-elements-input';
import { LinkTheme } from '@allergan-data-labs/alle-elements-link';
import { ModalTheme } from '@allergan-data-labs/alle-elements-modal';
import { RadioTheme } from '@allergan-data-labs/alle-elements-radio';
import { TooltipTheme } from '@allergan-data-labs/alle-elements-tooltip';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: screenSizes.mobile,
      md: screenSizes.tablet,
      lg: screenSizes.desktop,
      xl: screenSizes.largeDesktop,
    },
  },
});

const ThemeProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

const componentThemes: AlleElementsComponentThemes = {
  // please keep alphabetical
  Accordion: AccordionTheme,
  Avatar: AvatarTheme,
  Badge: BadgeTheme,
  Breadcrumb: BreadcrumbTheme,
  Button: ButtonTheme,
  Card: CardTheme,
  Checkbox: CheckboxTheme,
  Form: FormTheme,
  FormLabel: FormLabelTheme,
  FormError: FormErrorTheme,
  Input: InputTheme,
  Link: LinkTheme,
  Modal: ModalTheme,
  Radio: RadioTheme,
  Tooltip: TooltipTheme,
};

const AlleThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <AlleElementsProvider componentTheme={componentThemes}>
      {children}
    </AlleElementsProvider>
  );
};

export { ThemeProvider, AlleThemeProvider };
